<template>
  <div class="Table" :key="renderKey">
    <div class="table__header">
      <div>
        <h6><b>Total rows with errors:</b> {{ totalErrors }}</h6>
      </div>
      <div class="table__end-align">
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small class="d-inline-block">
          <b-button :disabled="isLoading" class="table__end-align--button"
            @click="sendScale()"><b-icon
              icon="reply-fill" aria-hidden="true"></b-icon>
            Send Quote</b-button>
        </b-overlay>
      </div>
    </div>
    <b-table id="scales-table" :items="items" :fields="fields"
      :per-page="pageSize" :current-page="currentPage"
      show-empty>
      <template #empty="scope">
        <h6 class="emptyText">{{ scope.emptyText }}</h6>
      </template>
      <template #cell()="row">
        <b-form-input v-if="row.field.key !== 'index'" type="text"
          :value="row.value" v-model="row.value"
          :class="row.field.key + row.index"
          :style="`border: 1px solid ${isFieldInvalid(row) ? '#F44336' : '#00B900'}`"
          @change="verifyFields(row)">
        </b-form-input>
        <div v-else>{{ row.item.index + 1 }}</div>
      </template>
      <template #cell(country_pickup)="row">
        <b-form-select v-model="row.value" :options="countriesOptions"
          @change="updateCountryField(row)"
          :state="validateCountryInput(row)">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Select a country--</b-form-select-option>
          </template>
          <template #default>
            <span>{{ row.value }}</span>
          </template>
        </b-form-select>
        <span v-if="(row.field.key === 'country_pickup') &&
    !validateCountryInput(row)
    " class="invalid-feedback d-block" :style="`font-size: 12px`">
          <span v-if="!countriesOptions.find((opt) =>
    opt.value === row.value) &&
    (row.field.key === 'country_pickup')
    " :style="`color: red; font-weight: bold`">
            {{ "*" + row.value }}
          </span>
          Please select a valid country
        </span>
      </template>
      <template #cell(country_delivery)="row">
        <b-form-select v-model="row.value" :options="countriesOptions"
          @change="updateCountryField(row)"
          :state="validateCountryInput(row)">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Select a country--</b-form-select-option>
          </template>
          <template #default>
            <span>{{ row.value }}</span>
          </template>
        </b-form-select>
        <span v-if="(row.field.key === 'country_delivery') &&
    !validateCountryInput(row)
    " class="invalid-feedback d-block" :style="`font-size: 12px`">
          <span v-if="!countriesOptions.find((opt) =>
    opt.value === row.value) &&
    (row.field.key === 'country_delivery')
    " :style="`color: red; font-weight: bold`">
            {{ "*" + row.value }}
          </span>
          Please select a valid country
        </span>
      </template>
      <template #cell(weight_units)="row">
        <b-form-select v-model="row.value" :options="weightUnitsOptions"
          @change="onChangeWeightUnit(row)"
          :state="validateWeightUnitsInput(row)">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Select a weight units--</b-form-select-option>
          </template>
          <template #default>
            <span>{{ row.value }}</span>
          </template>
        </b-form-select>
        <span v-if="(row.field.key === 'weight_units') &&
    !validateWeightUnitsInput(row)
    " class="invalid-feedback d-block" :style="`font-size: 12px`">
          <span v-if="!weightUnitsOptions.find((opt) =>
    opt.value === row.value) &&
    (row.field.key === 'weight_units')
    " :style="`color: red; font-weight: bold`">
            {{ "*" + row.value }}
          </span>
          Please select a valid weight unit
        </span>
      </template>
      <template #cell(nmfc_class)="row">
        <b-form-select v-model="row.value" :options="nmfcOptions" @change="onChangeNmfcClass(row)"
          :state="validateNmfcClassInput(row)">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Select a Nmfc_class--</b-form-select-option>
          </template>
          <template #default>
            <span>{{ row.value }}</span>
          </template>
        </b-form-select>
        <span v-if="(row.field.key === 'nmfc_class') &&
    !validateNmfcClassInput(row)
    " class="invalid-feedback d-block" :style="`font-size: 12px`">
          <span v-if="!nmfcOptions.find((opt) =>
    opt.value === row.value) &&
    (row.field.key === 'nmfc_class')
    " :style="`color: red; font-weight: bold`">
            {{ "*" + row.value }}
          </span>
          Please select a valid Nmfc_class
        </span>
      </template>
    </b-table>
    <el-pagination background layout="prev, pager, next" :total="totalRows" :page-size="pageSize"
      :current-page="currentPage" @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx';

export default {
  name: 'TableScalesByWeight',
  props: {
    file: {
      type: File,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        'index',
        'country_pickup',
        'zip_pickup',
        'country_delivery',
        'zip_delivery',
        'weight',
        'weight_units',
        'nmfc_class',
        'density',
      ],
      items: [],
      isThereError: false,
      pageSize: 10,
      currentPage: 1,
      rows: 0,
      isLoading: false,
      totalErrors: 0,
      validations: {
        canadaZipCode: /^[A-Za-z]\d[A-Za-z]([ -]?\d[A-Za-z]?\d?)?$/,
        weight: /^(lb|kg|ton)$/i,
        number: /^-?\d+(\.\d+)?$/,
        country: /^(us|mx|ca)$/i,
        nmfcClass: /^(50|55|60|65|70|85|92.5|100|125|150|175|250|300|400)$/i,
      },
      countriesOptions: [
        { value: 'US', text: 'US' },
        { value: 'MX', text: 'MX' },
        { value: 'CA', text: 'CA' },
      ],
      weightUnitsOptions: [
        { value: 'lb', text: 'lb' },
        { value: 'kg', text: 'kg' },
        { value: 'ton', text: 'ton' },
      ],
      nmfcOptions: [
        { text: '50', value: '50' },
        { text: '55', value: '55' },
        { text: '60', value: '60' },
        { text: '65', value: '65' },
        { text: '70', value: '70' },
        { text: '85', value: '85' },
        { text: '92.5', value: '92.5' },
        { text: '100', value: '100' },
        { text: '125', value: '125' },
        { text: '150', value: '150' },
        { text: '175', value: '175' },
        { text: '250', value: '250' },
        { text: '300', value: '300' },
        { text: '400', value: '400' },
      ],
      classRanges: {
        400: { min: 0, max: 1 },
        300: { min: 1, max: 2 },
        250: { min: 2, max: 4 },
        175: { min: 4, max: 6 },
        150: { min: 6, max: 7 },
        125: { min: 7, max: 8 },
        100: { min: 8, max: 10 },
        92.5: { min: 10, max: 12 },
        85: { min: 12, max: 15 },
        70: { min: 15, max: 22.5 },
        65: { min: 22.5, max: 30 },
        60: { min: 30, max: 35 },
        55: { min: 35, max: 50 },
        50: { min: 50, max: 999999999 },
      },
      countries: ['US', 'MX', 'CA'],
      renderKey: 0,
    };
  },
  computed: {
    totalRows() {
      return this.items.length;
    },
  },
  mounted() {
    if (this.file instanceof FileList) {
      this.$message({
        showClose: true,
        type: 'success',
        message: 'The file has been successfully uploaded',
        showConfirmButton: false,
        duration: 2000,
      });
      this.readingFile({ target: this.file });
    } else if (this.file instanceof File) {
      this.$message({
        showClose: true,
        type: 'success',
        message: 'The file has been successfully uploaded',
        showConfirmButton: false,
        duration: 2000,
      });
      this.readingFile({ target: [this.file] });
    }
  },
  methods: {
    changeAvailablePage(pageNumber) {
      this.currentPage = pageNumber;
      // eslint-disable-next-line
      this.tableKey++;
    },
    handleCurrentChange(val) {
      this.changeAvailablePage(val);
    },
    async readingFile() {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      // eslint-disable-next-line func-names
      reader.onload = async function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        this.scalesJson = XLSX.utils.sheet_to_json(worksheet);
        // Se filtra para sólo leer las filas que tengan información en al menos una columna
        this.scalesJson = this.scalesJson.filter((element) => element.country_delivery
        || element.country_pickup
        || element.density
        || element.nmfc_class
        || element.weigh
        || element.weight_units
        || element.zip_delivery
        || element.zip_pickup);
        const groupedRows = {};
        this.scalesJson.forEach((element, index) => {
          const pickupZip = element.zip_pickup && element.zip_pickup.toString();
          const deliveryZip = element.zip_deliverys && element.zip_delivery.toString();
          const key = `${pickupZip ? pickupZip.length : ''}_${deliveryZip ? deliveryZip.length : ''}`;
          if (!groupedRows[key]) {
            groupedRows[key] = [];
          }
          groupedRows[key].push({ element, index });
        });
        await this.scalesJson.forEach(async (element, index) => {
          const inputElements = document.querySelectorAll(`.${element.country_pickup + index}`);
          // eslint-disable-next-line
          element.inputElements = inputElements;
          if (element.zip_pickup && element.zip_delivery) {
            const pickupZip = element.zip_pickup.toString();
            const deliveryZip = element.zip_delivery.toString();
            // eslint-disable-next-line
            if (this.getLength(pickupZip)  === 4 && element.country_pickup.toUpperCase() === 'US'
            // eslint-disable-next-line
            || this.getLength(deliveryZip)  === 4 && element.country_delivery.toUpperCase() === 'US') {
              // Show confirmation dialog for all affected rows
              this.showConfirmationDialog(groupedRows);
            }
          }
        });
        await this.validateFile();
      }.bind(this);
      this.renderKey += 1;
    },
    getLength(value) {
      return String(value).length;
    },
    async showConfirmationDialog(groupedRows) {
      // a i don't know with change this boolean from false to true it works
      let hasShownChangeMessage = true;
      let changeMessages = '';
      Object.keys(groupedRows).forEach((key) => {
        const rows = groupedRows[key];
        if (hasShownChangeMessage && rows.length > 1) {
          rows.forEach((row) => {
            const pickupZip = row.element.zip_pickup ? row.element.zip_pickup.toString() : '';
            const deliveryZip = row.element.zip_delivery ? row.element.zip_delivery.toString() : '';
            if ((row.element.country_pickup === 'US'
            && this.getLength(pickupZip) === 4)
            || (row.element.country_delivery === 'US'
            && this.getLength(deliveryZip) === 4)) {
              // eslint-disable-next-line
              changeMessages += 'In the file you have found US zip codes with 4 digits, would you like to continue to correct each one?';
            }
          });
        } else {
          const pickupZip = rows[0].element.zip_pickup ? rows[0].element.zip_pickup.toString() : '';
          const deliveryZip = rows[0].element.zip_delivery ? rows[0].element.zip_delivery.toString() : '';
          // eslint-disable-next-line
          if ((rows[0].element.country_pickup === 'US' && this.getLength(pickupZip) === 4) || (rows[0].element.country_delivery === 'US' && this.getLength(deliveryZip) === 4)) {
          // eslint-disable-next-line
          changeMessages += 'In the file you have found US zip codes with 4 digits, would you like to continue to correct each one?';
          }
        }
      });
      changeMessages = changeMessages.trim();
      const fieldsToVerify = [];
      if (changeMessages) {
        Object.keys(groupedRows).forEach((key) => {
          const rows = groupedRows[key];
          rows.forEach((row) => {
            if ((row.element.country_pickup === 'US' && this.getLength(row.element.zip_pickup) === 4)) {
              fieldsToVerify.push({
                item: row.element,
                field: { key: 'zip_pickup' },
                index: row.index,
                value: row.element.zip_pickup.toString(),
              });
            }
            if ((row.element.country_delivery === 'US' && this.getLength(row.element.zip_delivery) === 4)) {
              fieldsToVerify.push({
                item: row.element,
                field: { key: 'zip_delivery' },
                index: row.index,
                value: row.element.zip_delivery.toString(),
              });
            }
          });
        });
        hasShownChangeMessage = true;
      }
      this.verifyZipCodeFields(fieldsToVerify);
      return true;
    },
    async validateFile() {
      const infoScaleErrors = [];
      this.items = [];
      let totalErrors = 0;
      this.scalesJson.forEach((element, index) => {
        const errorCordinates = {};
        this.isThereError = false;
        this.scalesJson[index].index = index;
        const requiredFields = ['weight', 'density'];
        requiredFields.forEach((field) => {
          if (!element[field]) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const numericFields = ['weight', 'density'];
        numericFields.forEach((field) => {
          if (!this.validations.number.test(element[field])) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const requiredFieldUnitsWeight = ['weight_units'];
        requiredFieldUnitsWeight.forEach((field) => {
          if (!element[field]) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const requiredFieldsUnitsWeight = ['weight_units'];
        requiredFieldsUnitsWeight.forEach((field) => {
          if (!this.validations.weight.test(element[field])) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const requiredFieldNmfcClass = ['nmfc_class'];
        requiredFieldNmfcClass.forEach((field) => {
          if (!element[field]) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        const requiredFieldsNmfcClass = ['nmfc_class'];
        requiredFieldsNmfcClass.forEach((field) => {
          if (!this.validations.nmfcClass.test(element[field])) {
            errorCordinates[field] = false;
            this.isThereError = true;
          }
        });
        if (element.zip_delivery && element.zip_pickup) {
          const deliverySize = element.zip_delivery.toString();
          const pickupSize = element.zip_pickup.toString();
          if (
            !(this.validations.number.test(element.zip_delivery) && deliverySize.length >= 5)
            && !this.validations.canadaZipCode.test(element.zip_delivery)
          ) {
            errorCordinates.zip_delivery = false;
            this.isThereError = true;
          }
          if (
            !(this.validations.number.test(element.zip_pickup) && pickupSize.length >= 5)
            && !this.validations.canadaZipCode.test(element.zip_pickup)
          ) {
            errorCordinates.zip_pickup = false;
            this.isThereError = true;
          }
        } else if (element?.zip_delivery && !element?.zip_pickup) {
          errorCordinates.zip_pickup = false;
          this.isThereError = true;
        } else if (element?.zip_pickup && !element?.zip_delivery) {
          errorCordinates.zip_delivery = false;
          this.isThereError = true;
        }
        if (element.country_delivery && element.country_pickup) {
          // eslint-disable-next-line
          const countryPickupValid = this.validations.country.test(element.country_pickup);
          // eslint-disable-next-line
          const countryDeliveryValid = this.validations.country.test(element.country_delivery);
          if (
            !countryPickupValid
          ) {
            errorCordinates.country_pickup = false;
            this.isThereError = true;
          }
          if (
            !countryDeliveryValid
          ) {
            errorCordinates.country_delivery = false;
            this.isThereError = true;
          }
        } else if (element?.country_delivery && !element?.country_pickup) {
          errorCordinates.country_pickup = false;
          this.isThereError = true;
        } else if (element?.country_pickup && !element?.country_delivery) {
          errorCordinates.country_delivery = false;
          this.isThereError = true;
        }
        if (Object.keys(errorCordinates).length > 0) {
          this.items.push(element);
          // eslint-disable-next-line
          totalErrors++;
        } else {
          infoScaleErrors.push(errorCordinates);
        }
        this.totalErrors = totalErrors;
      });
      setTimeout(() => {
        this.underLineErrors(infoScaleErrors);
      }, 500);
    },
    updateCountryField(row) {
      const upperCaseValue = this.getUpperCaseValue(row.value);
      this.scalesJson[row.item.index][row.field.key] = upperCaseValue;
      // eslint-disable-next-line
      row.value = upperCaseValue;
    },
    validateCountryInput(row) {
      if (row.field.key === 'country_pickup' || row.field.key === 'country_delivery') {
        const upperCaseValue = this.getUpperCaseValue(row.value);
        return this.isCountryValid(upperCaseValue);
      }
      return null;
    },
    getUpperCaseValue(value) {
      return typeof value === 'string' ? value.toUpperCase() : value;
    },
    isCountryValid(country) {
      return ['US', 'MX', 'CA'].includes(country.toUpperCase());
    },
    validateWeightUnitsInput(row) {
      if (row.field.key === 'weight_units') {
        return this.isWeightUnitsValid(row.value);
      }
      return null;
    },
    isWeightUnitsValid(weightUnits) {
      return ['kg', 'ton', 'lb'].includes(weightUnits);
    },
    onChangeWeightUnit(row) {
      // eslint-disable-next-line
      row.value = row.value.toLowerCase();
      // eslint-disable-next-line
      this.scalesJson[row.item.index]['weight_units'] = row.value;
    },
    validateNmfcClassInput(row) {
      if (row.field.key === 'nmfc_class') {
        return this.isNmfcClassValid(row.value.toString());
      }
      return null;
    },
    isNmfcClassValid(nmfcClass) {
      return ['50', '55', '60', '65', '70', '85', '92.5', '100', '125', '150', '175', '250', '300', '400'].includes(nmfcClass.toString());
    },
    onChangeNmfcClass(row) {
      // eslint-disable-next-line
      row.value = row.value.toLowerCase();
      // eslint-disable-next-line
      this.scalesJson[row.item.index]['nmfc_class'] = row.value;
    },
    async underLineErrors(infoScaleErrors) {
      infoScaleErrors.forEach((rowInfo, index) => {
        const rowArray = Object.keys(rowInfo);
        rowArray.forEach((key) => {
          const inputElements = document.querySelectorAll(`.${key}${index}`);
          if (inputElements.length > 0) {
            inputElements[0].classList.add('error-border');
          }
        });
      });
    },
    isFieldInvalid(row) {
      const fieldKey = row.field ? row.field.key : null;
      const fieldValue = row.value || '';
      if (!fieldValue && fieldKey !== 'index') return true;
      if ((fieldKey === 'zip_pickup' || fieldKey === 'zip_delivery')) {
        if (this.scalesJson[row.item.index]?.country_pickup?.toUpperCase() === 'US') {
          return fieldValue.length === 4;
        }
        if (this.scalesJson[row.item.index]?.country_delivery?.toUpperCase() === 'US') {
          return fieldValue.length === 4;
        }
      }
      if (this.validations.weight.test(fieldValue)) return false;
      if (this.validations.number.test(fieldValue)) return false;
      if (this.isCountryValid(fieldValue)) return false;
      if (this.isWeightUnitsValid(fieldValue)) return false;
      if (this.isNmfcClassValid(fieldValue)) return false;
      return true;
    },
    async verifyFields(row) {
      const input = document.getElementsByClassName(row.field.key + row.index);
      if (row.field.key.includes('zip')) {
        if (!this.validations.canadaZipCode.test(row.value)) {
          if (this.validations.number.test(row.value) && row.value.length >= 5) {
            input[0].attributes[4].value = 'border: 1px solid #00B900';
            this.scalesJson[row.item.index][row.field.key] = row.value;
          } else if ((row.item.country_pickup === 'US' && this.getLength(row.value) === 4 && row.field.key === 'zip_pickup') || (row.item.country_delivery === 'US' && this.getLength(row.value) === 4 && row.field.key === 'zip_delivery')) {
            input[0].attributes[4].value = 'border: 1px solid #F44336';
            // eslint-disable-next-line
            const fullZipcode = '0' + row.value;
            this.scalesJson[row.item.index][row.field.key] = fullZipcode;
            // eslint-disable-next-line
            row.value = fullZipcode;
            await this.$swal({
              title: 'US Zipcode Interpretation',
              // eslint-disable-next-line
              text: 'We interpreted the zipcode "' + row.value + '" as "' + fullZipcode + '". Please confirm.',
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (!result.isConfirmed) {
                input[0].attributes[4].value = 'border: 1px solid #F44336';
                this.scalesJson[row.item.index][row.field.key] = row.value.slice(1);
              } else {
                input[0].attributes[4].value = 'border: 1px solid #00B900';
              }
            });
          } else {
            input[0].attributes[4].value = 'border: 1px solid #F44336';
          }
        } else {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        }
      } else if (row.field.key === 'density' || row.field.key === 'weight') {
        if (this.validations.number.test(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (row.field.key === 'country_pickup' || row.field.key === 'country_delivery') {
        if (this.isCountryValid(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value.toUpperCase();
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (row.field.key === 'weight_units') {
        if (this.isWeightUnitsValid(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      } else if (row.field.key === 'nmfc_class') {
        if (this.isNmfcClassValid(row.value)) {
          input[0].attributes[4].value = 'border: 1px solid #00B900';
          this.scalesJson[row.item.index][row.field.key] = row.value;
        } else {
          input[0].attributes[4].value = 'border: 1px solid #F44336';
        }
      }
    },
    async verifyZipCodeFields(rows) {
      const fullZipCodes = [];
      rows.forEach(async (row) => {
        const fieldKey = row.field.key;
        const fieldValue = row.value || '';
        const inputElement = document.querySelectorAll(`.${fieldKey}${row.index}`);
        if (!inputElement) return;
        if ((fieldKey === 'zip_pickup' || fieldKey === 'zip_delivery')) {
          if (!this.validations.canadaZipCode.test(fieldValue)) {
            if (this.validations.number.test(fieldValue) && fieldValue.length >= 5) {
              inputElement.style.border = '1px solid #00B900';
              this.scalesJson[row.index][fieldKey] = fieldValue;
            } else if ((fieldKey === 'zip_pickup' && row.item.country_pickup.toUpperCase() === 'US'
              && this.getLength(fieldValue) === 4) || (fieldKey === 'zip_delivery'
                && row.item.country_delivery.toUpperCase() === 'US'
                && this.getLength(fieldValue) === 4)) {
              const fullZipCode = fieldValue;
              fullZipCodes.push({ index: row.index, fieldKey, value: fullZipCode });
              this.scalesJson[row.index][fieldKey] = fullZipCode;
            }
          } else {
            inputElement.style.border = '1px solid #00B900';
            this.scalesJson[row.index][fieldKey] = fieldValue;
          }
        } else if (fieldKey === 'country_pickup' || fieldKey === 'country_delivery') {
          if (this.isCountryValid(fieldValue)) {
            inputElement.style.border = '1px solid #00B900';
            this.scalesJson[row.index][fieldKey] = fieldValue.toUpperCase();
          } else {
            inputElement.style.border = '1px solid #F44336';
          }
        } else if (this.validations.number.test(fieldValue)) {
          inputElement.style.border = '1px solid #00B900';
          this.scalesJson[row.index][fieldKey] = Number(fieldValue);
        } else {
          inputElement.style.border = '1px solid #F44336';
        }
      });
      if (fullZipCodes.length > 0) {
        await this.showFullZipCodeDialog(fullZipCodes);
      }
    },
    async showFullZipCodeDialog(fullZipCodes) {
      const zipcodesString = fullZipCodes.map(({ fieldKey, value }) => `${fieldKey}: ${value}`).join(', ');
      const result = await this.$swal({
        title: 'US Zipcode Interpretation',
        text: `We interpreted the following zipcodes: "${zipcodesString}". Do you want to add a 0 at the beginning?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      });
      fullZipCodes.forEach(({ index, fieldKey, value }) => {
        const inputElement = document.querySelectorAll(`.${fieldKey}${index}`);
        if (inputElement) {
          if (result.isConfirmed) {
            // eslint-disable-next-line
            this.scalesJson[index][fieldKey] = '0' + value;
          } else {
            this.scalesJson[index][fieldKey] = value;
          }
        }
      });
    },
    lineItemsDimension(weightBrakes, density, unitsWeight) {
      const dimensionsArray = [];
      weightBrakes.forEach((weight, index) => {
        let realWeight = 0;
        switch (unitsWeight[index]) {
        case 'kg':
          realWeight = weight * 2.205;
          break;
        case 'ton':
          realWeight = weight * 2205;
          break;
        default:
          realWeight = weight;
        }
        realWeight = parseFloat(realWeight.toFixed(2));
        const cbf = realWeight / density[index];
        let hu = (cbf * 1728) / 182400;
        hu = Math.ceil(hu);
        let height = (cbf * 1728) / (48 * 40 * hu);
        height = height.toFixed(2);
        const length = 48;
        const width = 40;
        dimensionsArray.push({
          hu,
          length,
          width,
          height,
          realWeight,
        });
      });
      return dimensionsArray;
    },
    async sendScale() {
      if (this.totalErrors === this.items.length) {
        this.isLoading = true;
        const weightBrakesArray = [];
        const densityArray = [];
        const weightUnitsArray = [];
        this.scalesJson.forEach((element) => {
          weightBrakesArray.push(element.weight);
          densityArray.push(element.density);
          weightUnitsArray.push(element.weight_units);
        });
        const dimensions = await this.lineItemsDimension(
          weightBrakesArray,
          densityArray,
          weightUnitsArray,
        );
        const hauls = this.haulsConstructor(dimensions);
        this.$store.commit('scale/setHauls', hauls);
        const response = await this.$store.dispatch('scale/sendQuote');
        if (response.status === 202) {
          this.$swal({
            icon: 'success',
            title: 'The scale has been sent',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({ name: 'ScalesByWeight', params: { resetInfo: true } });
          });
        }
        this.isLoading = false;
      } else {
        this.$swal({
          title: 'Error',
          text: 'Please resolve all fields problems',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonColor: '#e53935',
        });
      }
    },
    haulsConstructor(dimensions) {
      const hauls = [];
      // eslint-disable-next-line
      const scalesJson = this.scalesJson;
      dimensions.forEach((dimension, index) => {
        const lineItems = {
          weight: parseFloat(dimension.realWeight),
          length: parseFloat(dimension.length),
          width: parseFloat(dimension.width),
          height: parseFloat(dimension.height),
          hu_count: dimension.hu,
        };
        const haul = {
          order: hauls.length + 1,
          line_items: [lineItems],
          zip_from: [],
          zip_to: [],
          nmfc_class: '',
          country_from: [],
          country_to: [],
        };
        if (index < scalesJson.length) {
          const scaleElement = scalesJson[index];
          if ((!scaleElement.zip_pickup && !scaleElement.zip_delivery)
            || (!scaleElement.country_pickup && !scaleElement.country_delivery)) {
            // Do nothing if there is no pickup or delivery information
          } else {
            haul.zip_from = String(scaleElement.zip_pickup);
            haul.zip_to = String(scaleElement.zip_delivery);
            haul.country_from = scaleElement.country_pickup.toString().toUpperCase();
            haul.country_to = scaleElement.country_delivery.toString().toUpperCase();
            haul.nmfc_class = String(scaleElement.nmfc_class);
          }
        }
        hauls.push(haul);
      });
      return hauls;
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  &__pagination {
    position: relative;
    z-index: 0;
    padding-bottom: 6rem;
    margin-bottom: 50px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__end-align {
    display: flex;
    justify-content: end;
    margin-top: 2rem;

    &--button {
      @include primary-button;
      margin: 30px;
    }
  }
}

.emptyText {
  display: flex;
  height: 35rem;
  flex-direction: column;
  justify-content: center;
}

.error-border {
  border: 1px solid #f44336;
}
:deep {
  .custom-select.is-valid {
    background: url('data:image/svg+xml,%3csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%224%22 height=%225%22 viewBox=%220 0 4 5%22%3e%3cpath fill=%22234a40%22 d=%22M2 0L0 2h4zm0 5L0 3h4z%22/%3e%3c/svg%3e') right 0.75rem center / 8px 10px no-repeat,#fff;
  }
}
</style>
